import "../scss/foldable_section.scss";
import {plugin_registry, PluginBase} from "../../../main/js/nk-plugin-registry";
import gsap from "gsap";

@plugin_registry.register("FoldableSection")
class FoldableSection extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;
    this._$title = $node.querySelector('[data-js-select="title"]');
    this._$content = $node.querySelector('[data-js-select="content"]')
    this._open = false;
  }

  connect($node) {
    this._$title.addEventListener('click', this._toggle_foldable_section)
  }

  disconnect($node) {
    this._$title.removeEventListener('click', this._toggle_foldable_section)
  }

  get open() {
    return this._open;
  }

  set open(new_value) {
    if (new_value) {
      this.$node.classList.add('opened');
      gsap.to(this._$content, {duration: 0.5, height: 'auto'})
    } else {
      this.$node.classList.remove('opened');
      gsap.to(this._$content, {duration: 0.5, height: 0})
    }

    this._open = new_value
  }

  _toggle_foldable_section = (event) => {
    this.open = !this.open;
  }
}
