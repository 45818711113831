import "../scss/image_section.scss";

import { plugin_registry, PluginBase } from "nk-plugin-registry";

import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";


@plugin_registry.register("ImageSection")
class ImageSection extends PluginBase {
  constructor($node) {
    super($node);

    this._gallery_root = $node.querySelector('[data-js-select="gallery"]');
    this._viewer = new Viewer(
      this._gallery_root,
      {
        toolbar: {
          zoomIn: false,
          zoomOut: false,
          oneToOne: false,
          reset: false,
          prev: true,
          play: {
            show: false,
            size: 'large',
          },
          next: true,
          rotateLeft: false,
          rotateRight: false,
          flipHorizontal: false,
          flipVertical: false,
        },
        url: (image) => (image.dataset['originalUrl']),
      }
    );
  }
}
