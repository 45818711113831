import "scss/components/audio_player.scss";
import { plugin_registry, PluginBase } from "../nk-plugin-registry";

const PLAYING_INDICATOR_CENTER = {
  x: 32,
  y: 32,
};

const PLAYING_INDICATOR_RADIUS = 29;
const PLAYING_UPDATE_INTERVAL = 125;

@plugin_registry.register("AudioPlayer")
class AudioPlayer extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;
    this._$audio = $node.querySelector('[data-js-select="audio"]');
    this._$indicator = $node.querySelector('[data-js-select="player-indicator"]');
    this._$player_text = $node.querySelector('[data-js-select="player-text"]');
    this._$progress_indicator = $node.querySelector('[data-js-select="progress-indicator"]');
    this._$play_button = $node.querySelector('[data-js-select="play-button"]');
    this._$pause_button = $node.querySelector('[data-js-select="pause-button"]');
    this._playing = false
    this._playing_timer = null;
    this._playing_from = 0;
    window.audio = this._$audio;
  }

  connect($node) {
    super.connect($node);

    this._$play_button.addEventListener("click", this._handle_play_click);
    this._$pause_button.addEventListener("click", this._handle_pause_click);
    this._$audio.addEventListener("ended", this._handle_pause_click);
  }

  _handle_play_click = (event) => this.play();
  _handle_pause_click = (event) => this.pause();

  get playing() {
    return this._playing;
  }

  play = () => {
    const _playing_promise = this._$audio.play();
    this._playing = true;
    this.$node.classList.add('playing');

    _playing_promise.then(
      () => {
        const playing_duration = this._$audio.duration;
        this._playing_timer = window.setInterval(
          () => {
            const playing_position = this._$audio.currentTime;
            const playing_to = (360 / playing_duration) * (playing_position);
            this._$progress_indicator.setAttribute(
              'd',
              this._generate_arc_description(
                PLAYING_INDICATOR_CENTER,
                PLAYING_INDICATOR_RADIUS,
                playing_to,
              ),
            );
          },
          PLAYING_UPDATE_INTERVAL
        )
      }
    )
  }

  pause = () => {
    this.$node.classList.remove('playing');
    this._$audio.pause();
    window.clearInterval(this._playing_timer);
    this._playing = false;
  }

  _polar_to_cartesian = (center, radius, radiants) => ({
    x: center.x + (radius * Math.cos(radiants)),
    y: center.x + (radius * Math.sin(radiants)),
  });

  _generate_arc_description = (center, radius, deg) => {
    const end_arch = (deg - 90) * Math.PI / 180.0;
    const end_coordinate = this._polar_to_cartesian(center, radius, Math.PI / -2);
    const start_coordinate = this._polar_to_cartesian(
      center,
      radius,
      end_arch,
    );

    const short_arch = (360 / 2 < deg) ? 1 : 0;

    return [
      'M', start_coordinate.x, start_coordinate.y,
      // adding 0.00001 to the end coordinate's x value ensures closed circle
      'A', radius, radius, 0, short_arch, 0, end_coordinate.x + 0.00001, end_coordinate.y,
    ].join(' ');
  };

  _update_playing_indicator = (start, stop, duration) => {
    const total_steps = 60 * duration / 1000;
    const timeout = duration / 60000;

    const ease_function = (x) => x;

    let step_counter = 0;

    const interval = window.setInterval(
      () => {
        const progress = step_counter / total_steps;

        if (step_counter >= total_steps) {
          window.clearInterval(interval);
        }

        step_counter += 1;
      },
      timeout,
    );
  };

}
